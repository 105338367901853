import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Tab } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";



import bgimage from "../../../../images/no-img-avatar.png";
import { useDispatch, connect } from "react-redux";
import {
    CreateAdvertising,
    DeleteAdvertising,
    GetAllAdvertising,
} from "../../../../store/actions/AdvertisingActions";
import API_CONFIG from "../../../../apiConfig";

const DropdownBlog = ({ editItem, deleteItem }) => {
    const dispatch = useDispatch();
    const { register, handleSubmit } = useForm();
  
    return (
      <>
        <Dropdown className="">
          <Dropdown.Toggle
            as="div"
            className="btn-link i-false"
            style={{ cursor: "pointer" }}
          >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash text-primary pe-auto" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
          </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item className="text-danger" onClick={() => deleteItem()}>Delete</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

const Advertising = () => {

  const advertising = useSelector((state) => state.advertising.data);
  console.log("advertising", advertising); 
  const merchant = useSelector((state) => state.merchant.data);
  const mediaUrl = API_CONFIG.mediaUrl;
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/advertising") {
        dispatch(GetAllAdvertising());
    }    

  }, [location.pathname]);

  // For Image upload in ListBlog
  const [advertisingImage, setAdvertisingImage] = useState(null);
  const [advertisingImageBlobPreview, setAdvertisingImageBlobPreview] =
    useState(merchant.advertising_image || false);
  const [advertisingImageBlob, setAdvertisingImageBlob] =
    useState(false);
  const [advertisingImageUpdated, setAdvertisingImageUpdated] = useState(false);  
  const [imageLink, setImageLink] = useState(null);
  const [deletedItem, setDeletedItem] = useState(false);

  const closed = "Closed";

  let errorsObj = {
    businessName: "",
    email: "",
    password: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
  };

  const dispatch = useDispatch();

  const updateLink = (value) => {
    setImageLink(value);
    console.log("Link", imageLink);
  }

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const fileHandler = (e, imageType) => {
    const value = e.target.files[0];

    imageType === "advertisement" && setAdvertisingImage(value);
    // Preview image
    const blob = URL.createObjectURL(value);

    imageType === "advertisement" && setAdvertisingImageBlob(blob);
    imageType === "advertisement" && setAdvertisingImageUpdated(true);
  };

  function RemoveImage(imageType) {
    imageType === "advertisement" && setAdvertisingImageBlob(`${mediaUrl}/${merchant.advertising_image}`);
  }

  function handleDelete(id) {
    dispatch(DeleteAdvertising(id));
    setDeletedItem(true);
  }

  useEffect(() => {
    return () => {
      setAdvertisingImageBlob(false);
      setDeletedItem(false);
    };
  }, [location]); 


  function handleSave() {
    console.log("image link", imageLink);
    const formData = new FormData();
    formData.append("image_url", advertisingImage !== null && advertisingImage);
    formData.append("link", imageLink || "");
    formData.append("_method", "post");
    dispatch(CreateAdvertising(formData));
    const confirmation = document.querySelector(".confirmation-message");
    setAdvertisingImageUpdated(false);
    confirmation.textContent = "Advertisment Updated!";
  }

  return (
    <>
      <div className="column">
        <Tab.Container defaultActiveKey="Account">
          <div className="col-xl-8">
            <Tab.Content>
              <Tab.Pane eventKey="Account" key="account">
                <form onSubmit={ handleSubmit(handleSave) }>
                  <div className="setting-right">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="mb-4">Advertising</h3>

                        { /* Advertisment Image Preview */}
                        <div className="setting-img d-flex align-items-center mb-4">
                          <div className="avatar-upload d-flex align-items-center">
                            <div className=" change position-relative d-flex">
                              <div className="avatar-preview">
                                <p>Upload Image</p>
                                <img
                                  src={
                                    advertisingImageUpdated
                                      ? `${advertisingImageBlob}`
                                      :   merchant.advertising_image
                                      ? `${mediaUrl}/${merchant.advertising_image}`
                                      : bgimage
                                  }
                                  alt={
                                    advertisingImageBlob
                                      ? advertisingImageBlob.name
                                      : null
                                  }
                                />
                              </div>
                              <div className="change-btn d-flex align-items-center flex-wrap">
                                <input
                                  type="file"
                                  id="advertisement-image-upload"
                                  {...register("image_url")}
                                  onChange={(e) => fileHandler(e, "advertisement") }
                                  className="form-control"
                                  accept=".png, .jpg, .jpeg"
                                />
                                <label
                                  htmlFor="advertisement-image-upload"
                                  className="dlab-upload"
                                >
                                  Choose File
                                </label>
                                <button
                                  type="button"
                                  className="btn remove-img ms-2"
                                  onClick={() => RemoveImage("advertisement")}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-6 col-sm-6">
                            <div className="form-group">
                              <label className="form-label">Link to site:</label>
                              <input
                                type="text"
                                className="form-control mb-3"
                                {...register("link")}
                                id="link"
                                onChange={(e) => setImageLink(e.target.value) }
                                placeholder="https://example.com"
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 col-sm-6">
                            <button
                              type="submit"
                              className="btn btn-primary float-end w-50 btn-md"
                            >
                              Add Advertisement
                            </button>
                            <p className="confirmation-message text-success fw-bold"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </Tab.Pane>
            </Tab.Content>
          </div>
          <div className="col-xl-12">
            <div className="card">
                <div className="card-body">
                    <h3 className="mb-4">All Advertisements</h3>
                    <ul className="advertising-list">
                        {advertising && advertising.length > 0 && advertising.map((ad) => (
                            ad.image_url && (
            
                            <li className="advertising-list__item" key={ad.id}>
                                <div className="advertising-list__image-container">
                                    <img className="advertising-list__image" src={`${mediaUrl}/${ad.image_url}`} alt="" />
                                </div>
                                <div className="advertising-list__link mt-2 mb-2">
                                    <span>Link: </span>
                                    <a href={ad.link} target="_blank" rel="noreferrer">{ad.link}</a>
                                </div>
                                <div className="ml-1">
                                    <DropdownBlog
                                        deleteItem={() => handleDelete(ad.id)}
                                    />
                                </div>
                            </li>
              
                            )
                        ))}
                    </ul>
                </div>
            </div>
        </div>
        </Tab.Container>


      </div>
    </>
  );
};

export default Advertising;
